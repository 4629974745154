import React from "react"

import Layout      from "../components/share/upcc-layout"
import Header      from "../components/Header/upcc"
import WallGallery from "../components/upcc/wall-gallery"
import Gallery     from "../components/upcc/gallery"
import Footer      from "../components/share/footer"


export default () => {

  return (
    <Layout
      title="Exhibiciones | UPC Cultural"
      metaTitle="Exhibiciones | UPC Cultural"
      description="Entérate de las exhibiciones artísticas y culturales que han sido parte de la Galería de UPC Cultural."
      subtitile="Entérate de las exhibiciones artísticas y culturales que han sido parte de la Galería de UPC Cultural."
      metaDescripcin="Entérate de las exhibiciones artísticas y culturales que han sido parte de la Galería de UPC Cultural."
      imgurl="//cultural.upc.edu.pe/static/UpcCultural_Share-a1f8e23f2b58627c09d19c7bfc611366.jpg"
      keywords="exhibicion, exhibiciones, galeria, UPC, UPC Cultural"
    >
      <Header/>

      <div class="upcc-container">

        <WallGallery/>

        <Gallery/>

      </div>

      <Footer/>
    </Layout>
  )
}
