import   React            from "react"
import { useLocation }    from "@reach/router"

import { graphql,
         useStaticQuery,
         Link }           from "gatsby"

import { normalize } from "../../hooks/normalize"
import { date      } from "../../hooks/upcc-date"


export default () => {
  const home = (useLocation().pathname == '/') ? true : false

  const { allContentfulPortalCarruselDeImagenes: { nodes } } = useStaticQuery(
    graphql`
    {
      allContentfulPortalCarruselDeImagenes(limit: 3, filter: {node_locale: {eq: "en-US"}}, sort: {fields: IndexDate, order: DESC}) {
        nodes {
          IndexImage{ file { url } }

          IndexTiltle
          IndexSubtitle

          IndexDate
        }
      }
    }
  `); console.log({ nodes })


  return (
    <>
      <section class="wall dark">
        <h1 class="upcc-h1 white center">Galería UPC</h1>
        <h3 class="upcc-h3 white center">Nuestras últimas exhibiciones</h3>
        <div class="wall-flex">
          {
            nodes.map(( node, i) =>
              <article>
                <Link to={`/galeria/${normalize(node.IndexTiltle)}`}>
                  <img class="upcc-img" src={ node.IndexImage.file.url } />
                  <span class="upcc-tag blue">EXHIBICIÓN</span>
                  <h5 class="upcc-h5 small white">{ node.IndexTiltle }</h5>
                  <p class="upcc-small small white">{ node.IndexSubtitle }</p>
                  <span class="upcc-date white">{ date(node.IndexDate).str }</span>
                </Link>
              </article>
            )
          }
        </div>

        {home
          ? <Link className="more white center" to={`/galeria`}>VER MÁS<br/><i class="fa fa-angle-down"></i></Link>
          : <a class="more white center" href="#more">VER MÁS<br/><i class="fa fa-angle-down" id="more"></i></a>
        }
      </section>
    </>
  )
}