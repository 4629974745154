import React, { useState } from 'react'

import { graphql,
         useStaticQuery,
         Link }           from "gatsby"

import { normalize } from "../../hooks/normalize"
import { date      } from "../../hooks/upcc-date"

import arrow from "../../images/upcc/arrow.svg"


export default () => {

  let { allContentfulPortalCarruselDeImagenes: { nodes } } = useStaticQuery(
    graphql`
    {
      allContentfulPortalCarruselDeImagenes(filter: {node_locale: {eq: "en-US"}}, sort: {fields: IndexDate, order: DESC}) {
          nodes {
          id
          IndexTiltle
          IndexDate
          IndexSubtitle
          IndexImage{
            file {
              url
            }
          }
        }
      }
    }
  `); console.log({ nodes })

  const N = nodes.length
  const n = 12

  for(let i = 0; i < n; i++) if(nodes[i]) nodes[i].visible = true

  let _visible = N - n ? true : false

  let [posts, setPosts]     = useState(nodes)
  let [visible, setVisible] = useState(_visible)

  function more() {
    let _posts = [...posts]

    const i = _posts.findIndex(e => !e.visible)

    if(i != -1) {
      for(let j = i; j < i + n; j++) {
        if(_posts[j])
          _posts[j].visible = true
      }
    }

    setPosts(_posts)

    if(_posts.findIndex(e => !e.visible) == -1) setVisible(false)
  }


  return (
    <>
      <section class="upcc-menu">
        <nav>
          <Link to={`/home`}>Inicio</Link>&nbsp;/&nbsp;
          <Link to={`/galeria#more`}>Galería</Link>
        </nav>
      </section>

      <section class="upcc-controls">
        <div class="left">
          <span class="bar"></span>
          <h5 class="upcc-h5">Exhibiciones anteriores</h5>
        </div>
        <div class="right"></div>
      </section>

      <section class="upcc-cards two">
        {
          posts.map(( post, i ) => {
            if(i > 2  && post.visible) return (
              <article class="upcc-card">
                <Link to={`/galeria/${normalize(post.IndexTiltle)}`}>
                  <img class="upcc-card-img" src={ post.IndexImage.file.url } />
                </Link>
                <span class="upcc-tag blue upcc-card-tag">EXHIBICIÓN</span>
                <Link to={`/galeria/${normalize(post.IndexTiltle)}`}>
                  <h5 class="upcc-h5 upcc-card-title">{ post.IndexTiltle }</h5>
                </Link>
                <p class="upcc-small upcc-card-desc">{ post.IndexSubtitle }</p>
                <footer>
                  <span class="upcc-small upcc-card-date">{ date(post.IndexDate).str }</span>
                  <Link class="upcc-card-more" to={`/galeria/${normalize(post.IndexTiltle)}`}>
                    <p class="upcc-small underline">Ver Ahora</p>
                    <img src={arrow} />
                  </Link>
                </footer>
              </article>
            )
          })
        }
      </section>

      <section class="upcc-more">
        {visible
          ? <button class="upcc-button" onClick={more}>Ver más</button>
          : <i/>
        }
      </section>
    </>
  )
}